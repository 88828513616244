import {
  contentful,
  ContentfulQueryKeys,
  useContentfulQuery,
} from '@oresundsbron/api';
import { useRouter } from 'next/router';
import { createMetaDataItems } from '../lib/metadata';
import { PageFilter } from '../types/page';
import { useLinks } from './useLinks';
import { useSEOMetadata } from './useSEOMetaData';
import { mainImageFromPage } from '~/lib/mainImageFromPage';

export function usePage(filter: PageFilter) {
  const { locale, isPreview } = useRouter();
  const { getLink } = useLinks();
  let { data, error, isLoading } = useContentfulQuery(
    ContentfulQueryKeys.Page({ ...filter, locale, preview: isPreview }),
    (q) => q.Page.get({ ...filter, locale, preview: isPreview }),
    { staleTime: Infinity, enabled: !!filter.id }
  );

  const link = getLink(filter.id);

  const mainImage = mainImageFromPage(data);

  const metadataItems = createMetaDataItems({
    ...data,
    mainImage,
    link,
    contentType: 'website',
  });

  const seoMetadata = useSEOMetadata(data?.metadata, metadataItems);

  return { page: data, error, seoMetadata, isLoading };
}

export const prefetchPage = contentful.Page.prefetchGet;
