import { FC, useMemo } from 'react';
import { mapWithIndex as mapA, takeLeft } from 'fp-ts/lib/ReadonlyArray';
import { pipe } from 'fp-ts/lib/function';
import {
  chain,
  fromNullable,
  fromPredicate,
  map,
  match,
  toUndefined,
} from 'fp-ts/lib/Option';

import { Box, Typography } from '@oresundsbron/bridge-ui';
import { cx } from 'class-variance-authority';
import { useLinks } from '../hooks/useLinks';
import { reduceWithIndex } from 'fp-ts/lib/Array';
import { DefaultLink } from '~/components/Links/DefaultLink';
import { ChevronRight } from '@oresundsbron/icons';

export const Breadcrumbs: FC<{
  id: string;
  invert?: boolean;
  className?: string;
  addRight?: { path: string; title: string }[];
  addLeft?: { path: string; title: string }[];
}> = ({ id, invert, className }) => {
  const { getLink, findLink } = useLinks();

  const crumbs = useMemo(
    () =>
      pipe(
        getLink(id),
        fromNullable,
        map((c) =>
          pipe(
            c.slugs,
            reduceWithIndex([] as Array<typeof c>, (i, res) =>
              pipe(
                c.slugs,
                takeLeft(i + 1),
                (s) => s.join('/'),
                (s) => `/${s}`,
                findLink,
                chain(fromPredicate((x) => !x.skip)),
                match(
                  () => res,
                  (l) => [...res, l]
                )
              )
            )
          )
        ),
        map(mapA((_, l) => ({ path: l.path, title: l.title }))),
        chain(fromPredicate((x) => x.length > 1)),
        toUndefined
      ),
    [id, getLink, findLink]
  );

  return crumbs ? (
    <Box
      as={'ul'}
      itemScope
      itemType="https://schema.org/BreadcrumbList"
      className={cx('flex flex-wrap gap-1 text-sm', className)}
    >
      {crumbs.map(({ path, title }, i) => (
        <Box
          key={path}
          as="li"
          className="flex items-center gap-1"
          itemProp="itemListElement"
          itemScope
          itemType="https://schema.org/ListItem"
        >
          {i !== crumbs.length - 1 ? (
            <DefaultLink
              itemProp="item"
              itemType="https://schema.org/WebPage"
              href={path}
              color={!!invert ? 'inherit' : 'neutral'}
              className={cx(
                'hover:no-underline',
                !!invert ? 'text-white' : undefined,
                'underline'
              )}
            >
              <span itemProp="name">{title}</span>
            </DefaultLink>
          ) : (
            <Typography
              as="span"
              size={'md'}
              className={cx(!!invert ? 'text-neutral-400' : 'text-neutral-500')}
            >
              <span itemProp="name">{title}</span>
            </Typography>
          )}
          {i !== crumbs.length - 1 ? (
            <Box
              as="span"
              aria-hidden
              className={cx(!!invert ? 'text-neutral-400' : 'text-neutral-500')}
            >
              <ChevronRight className="size-4 text-type-secondary" />
            </Box>
          ) : null}
          <meta itemProp="position" content={(i + 1).toString()} />
        </Box>
      ))}
    </Box>
  ) : null;
};
